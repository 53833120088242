import classnames from 'classnames'
import throttle from 'lodash.throttle'
import * as React from 'react'

import { Delayed } from '../../../main/components/delayed'
import { Spinner } from '../../../main/components/spinner'
import { ISettings } from '../constants/settings'
import LayoutGenerator from '../layout-generator'
import mapActionCreators from '../store/action-creators'
import Treemap from '../treemap'
import { MapDataRoot, MapSubtype, MapType } from '../types'
import * as mapUtils from '../utils'
import Canvas from './Canvas'

const MapContext = React.createContext<{
  treemap?: Treemap
  setTreemap: React.Dispatch<React.SetStateAction<Treemap | undefined>>
  generator?: LayoutGenerator
  setGenerator: React.Dispatch<React.SetStateAction<LayoutGenerator | undefined>>
}>({ setTreemap: () => {}, setGenerator: () => {} })

export function useMapContext() {
  return React.useContext(MapContext)
}

export function MapContextProvider(props: React.PropsWithChildren<{}>) {
  const [treemap, setTreemap] = React.useState<Treemap>()
  const [generator, setGenerator] = React.useState<LayoutGenerator>()

  return (
    <MapContext.Provider value={{ treemap, setTreemap, generator, setGenerator }}>{props.children}</MapContext.Provider>
  )
}

interface MapProps {
  className?: string
  isLoadingData?: boolean
  isFetchingPerf?: boolean
  type: MapType
  subtype: MapSubtype
  data?: MapDataRoot
  settings?: ISettings
  onNodeClick?: React.ComponentProps<typeof Canvas>['onNodeClick']
  zoom?: boolean
  zoomOnWheel?: boolean
  legend?: boolean
  hover?: boolean
  truncateNodeName?: boolean
  dataHash?: string
}

export function Map({
  className,
  isLoadingData,
  isFetchingPerf = isLoadingData,
  type,
  subtype,
  data,
  settings,
  onNodeClick,
  zoom,
  zoomOnWheel,
  legend,
  hover,
  truncateNodeName,
  dataHash,
}: MapProps) {
  const { setGenerator, treemap: prevTreemap, setTreemap } = useMapContext()
  const containerRef = React.useRef<HTMLDivElement>(null)

  /**
   * We can’t `useEffect` for generator/treemap, because we’d have to deal with stale references in
   * other effects. In order to ensure we always get the current values, we need to either `useRef`
   * them (and update props for both state and ref) or `useMemo` and wait for first render to happen.
   */
  const [hasRendered, setHasRendered] = React.useState(false)

  /**
   * Generator makes the layout, it should only change when the map type or settings change.
   */
  const generator = React.useMemo(() => {
    if (!hasRendered || !containerRef.current) return

    const isSmall = mapUtils.getIsSmall()
    const size = mapUtils.getSize(containerRef.current, type.type, isSmall)
    const mapSettings = settings ?? mapUtils.getSettingsForMapType(type.type, isSmall)
    const generator = new LayoutGenerator(size.width, size.height, type.type, mapSettings, isSmall)

    // This is used for updating the map base data using map-generator
    window.MAP_EXPORT = generator

    return generator
  }, [hasRendered, type.type, settings])

  /**
   * Treemap object is the logical representation of the map (whereas Canvas is graphical)
   * We want to hold to it for as long as we can and reuse it. That’s why we’re comparing
   * `prevTreemap` and returning the prev value if it equals.
   */
  const treemap = React.useMemo(() => {
    if (!generator || !data || !dataHash || prevTreemap?.type === type.type) return prevTreemap

    return new Treemap({
      data: generator.getLayout(data),
      width: generator.width,
      height: generator.height,
      settings: generator.settings,
      isSmall: generator.isSmall,
      scale: { id: subtype.scaleId, name: subtype.label, tooltip: subtype.tooltip },
      countIndustryPerf: subtype.countIndustryPerf,
      countSectorPerf: generator.isSmall && subtype.countSectorPerf,
      type: type.type,
      subtype: subtype.value,
      truncateNodeName,
      mapNodeId: prevTreemap?.mapNodeId,
      dataHash,
      zoomLevels: zoom === false ? [1] : undefined,
    })
    // Can’t add `prevTreemap` because we’d end up in a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generator, data, type.type, subtype])

  /**
   * Update context values
   */
  React.useEffect(() => {
    setHasRendered(true)
    setGenerator(generator)
    setTreemap(treemap)
  }, [generator, setGenerator, setTreemap, treemap])

  /**
   * Handle data updates
   */
  const updateTimeoutRef = React.useRef<number>()
  React.useEffect(() => {
    if (!treemap || !generator || !data || isFetchingPerf) return

    updateTimeoutRef.current = window.setTimeout(() => {
      if (!dataHash || treemap.dataHash === dataHash) return

      const layout = generator.getLayout(data)
      mapActionCreators.updateLayout(treemap, {
        width: generator.width,
        height: generator.height,
        data: layout,
        scale: { id: subtype.scaleId, name: subtype.label, tooltip: subtype.tooltip },
        dataHash,
      })
    }, 100)

    return () => {
      if (updateTimeoutRef.current) window.clearTimeout(updateTimeoutRef.current)
    }
  }, [generator, treemap, data, isFetchingPerf, subtype, dataHash])

  /**
   * Recalculate layout when the window size changes
   */
  React.useEffect(() => {
    const containerElement = containerRef.current

    if (!generator || !containerElement || !treemap || !data) return

    const resize = throttle(() => {
      const size = mapUtils.getSize(containerElement, generator.type, generator.isSmall)

      if (size.width === treemap.width && size.height === treemap.height) return

      treemap.zoom.scale(1)
      generator.width = size.width
      generator.height = size.height
      const layout = generator.getLayout(data)
      mapActionCreators.updateLayout(treemap, {
        width: generator.width,
        height: generator.height,
        data: layout,
        scale: treemap.scale,
        dataHash: treemap.dataHash,
      })
    }, 100)

    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [generator, treemap, data])

  return (
    <div id="map" className={className}>
      <div
        ref={containerRef}
        id="body"
        className={classnames('relative h-full w-full', { 'pointer-events-none': !treemap })}
      >
        {(!treemap && !isLoadingData) || !treemap || isLoadingData ? (
          <Delayed delayComponent={<div className="h-full w-full" />}>
            <div className="flex h-full items-center justify-center">
              <Spinner />
            </div>
          </Delayed>
        ) : (
          <Canvas
            alt={document.title}
            treemap={treemap}
            onNodeClick={onNodeClick}
            zoom={zoom}
            zoomOnWheel={zoomOnWheel}
            legend={legend}
            hover={hover}
          />
        )}
      </div>
    </div>
  )
}
