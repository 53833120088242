import classnames from 'classnames'
import * as React from 'react'

import '../../../../styles/map-hover.css'
import Sparkline from '../../shared/components/Sparkline'
import MapActions from '../store/action-creators'
import MapStore from '../store/mapStore'
import Treemap from '../treemap'
import { MapDataNode, MapTypeId } from '../types'
import { getVisibleTooltipTickers } from '../utils'

interface CanvasHoverProps {
  treemap: Treemap
  mapType: MapTypeId
  mapNodeId: string
}

interface CanvasHoverState {
  visibleTickers: string[]
  activeNode?: MapDataNode
  sparklinesData: Record<string, number[]>
  mousePosition: { x: number; y: number }
}

class CanvasHover extends React.Component<CanvasHoverProps, CanvasHoverState> {
  elementRef = React.createRef<HTMLDivElement>()
  state: CanvasHoverState = {
    visibleTickers: [],
    activeNode: undefined,
    sparklinesData: {},
    mousePosition: { x: 0, y: 0 },
  }

  componentDidMount() {
    MapStore.addChangeListener(this._onChange)
    document.addEventListener('mousemove', this._onMouseMove)
  }

  componentWillUnmount() {
    MapActions.loadSparklinesCancel()
    MapStore.removeChangeListener(this._onChange)
    document.removeEventListener('mousemove', this._onMouseMove)
  }

  componentDidUpdate(_: CanvasHoverProps, prevState: CanvasHoverState) {
    const { activeNode, visibleTickers, sparklinesData } = this.state

    if (!activeNode || activeNode.name === prevState?.activeNode?.name) return

    if (!visibleTickers.includes(activeNode.name)) {
      visibleTickers.push(activeNode.name)
    }

    const tickersToLoad = visibleTickers.filter((ticker) => !sparklinesData[ticker])

    if (tickersToLoad.length) {
      MapActions.loadSparklineData(tickersToLoad, this.props.mapType)
    }
  }

  _onChange = () => {
    const activeNode = MapStore.getHoveredNode(this.props.mapNodeId)
    const sparklinesData = MapStore.getSparklines()

    this.setState({
      visibleTickers: getVisibleTooltipTickers(activeNode),
      activeNode,
      sparklinesData,
    })
  }

  _formatPerf = (d: MapDataNode) => {
    if (typeof d.additional !== 'undefined' && d.additional !== null) {
      return d.additional
    } else if (typeof d.perf !== 'undefined' && d.perf !== null) {
      return this.props.treemap.formatValue(d.perf.toFixed(2))
    } else {
      return 'N/A'
    }
  }

  render() {
    const d = this.state.activeNode
    if (!d) {
      return null
    }
    var hasSparkline =
      this.state.sparklinesData && this.state.sparklinesData[d.name] && this.state.sparklinesData[d.name].length > 0
    var price = hasSparkline
      ? this.state.sparklinesData[d.name][this.state.sparklinesData[d.name].length - 1].toFixed(2)
      : ''
    var colorScale = this.props.treemap.colorScale
    var type = this.props.treemap.type

    var children = d.parent.children
      .filter((node) => this.state.visibleTickers.includes(node.name))
      .sort((a, b) => b.dx * b.dy - a.dx * a.dy)

    var isSmall = children.length > 15
    var title = (type !== MapTypeId.World ? d.parent.parent.name + ' - ' : '') + d.parent.name
    return (
      <div ref={this.elementRef} id="hover" style={this._getPosition(this.state.mousePosition)}>
        <h3 className="text-left">{title}</h3>
        <table className={isSmall ? 'is-small' : ''}>
          <tbody>
            <tr key={d.name + '-hover'} className="hovered" style={{ backgroundColor: colorScale(d.perf) }}>
              <td className="ticker">{d.data?.data?.nameOverride ?? d.name}</td>
              <td>
                {hasSparkline ? (
                  <Sparkline className="white" width={65} height={25} data={this.state.sparklinesData[d.name]} />
                ) : null}
              </td>
              <td className="price">{price}</td>
              <td className="change">{this._formatPerf(d)}</td>
            </tr>
            <tr
              key={d.name + '-hover-description'}
              className={classnames('hovered is-description', { 'is-empty': !d.description })}
              style={{ backgroundColor: colorScale(d.perf) }}
            >
              <td
                // @ts-ignore
                colSpan="4"
                className={d.description && 'description'}
              >
                {d.description}
              </td>
            </tr>

            {children.length > 1 &&
              children.map((c) => {
                var hasSparkline =
                  this.state.sparklinesData &&
                  this.state.sparklinesData[c.name] &&
                  this.state.sparklinesData[c.name].length > 0
                var data = hasSparkline ? this.state.sparklinesData[c.name] : []
                return (
                  <tr key={c.name}>
                    <td className="ticker text-gray-900">{c.data?.data?.nameOverride ?? c.name}</td>
                    <td>
                      {hasSparkline ? (
                        <Sparkline width={65} height={isSmall ? 20 : 25} data={this.state.sparklinesData[c.name]} />
                      ) : null}
                    </td>
                    <td className="price text-gray-900">{hasSparkline ? data[data.length - 1].toFixed(2) : ''}</td>
                    <td className="change" style={{ color: colorScale(c.perf) }}>
                      {this._formatPerf(c)}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }

  _getPosition = ({ x, y }: { x: number; y: number }) => {
    if (!this.elementRef.current) return

    const result: { left?: string; top?: string } = {}
    let marginLeft = 100
    const marginTop = 0
    const hoverWidth = this.elementRef.current.scrollWidth
    const hoverHeight = this.elementRef.current.scrollHeight
    const availWidth = document.documentElement.clientWidth
    const availHeight = document.documentElement.clientHeight
    if (x + hoverWidth + marginLeft * 1.5 < availWidth) {
      result.left = x + marginLeft + 'px'
    } else if (x - hoverWidth - marginLeft > 0) {
      result.left = x - marginLeft - hoverWidth + 'px'
    } else {
      marginLeft = 20
      if (x + hoverWidth + marginLeft < availWidth) {
        result.left = x + marginLeft + 'px'
      } else {
        result.left = x - marginLeft - hoverWidth + 'px'
      }
    }
    result.top = Math.max(0, y + marginTop - Math.max(0, y + marginTop + hoverHeight - availHeight)) + 'px'

    return result
  }

  _onMouseMove = (e: MouseEvent) => {
    // In a transition so it’s debounced if need be
    React.startTransition(() => {
      this.setState({ mousePosition: { x: e.clientX, y: e.clientY } })
    })
  }
}

export default CanvasHover
